import {POST} from "@/services/gateway";

const listSnapshotDetails = ({objectType, status, searchTerm, snapshotId, pagination}) => {
  return POST('salesforce/search', {objectType, status, searchTerm, snapshotId, pagination})
}

const listBreadcrumps = ({userId, folderId}) => {
  return POST('salesforce/breadcrumbs', {userId, folderId})
}


export {
  listSnapshotDetails,
  listBreadcrumps
}
